<template>
<v-app>
    <SingleAppBar title="กล่องข้อความ" :backward="true" name="main"/>
    <v-main class="natural lighten-4 fill-height">
      <v-container class="pa-0 ma-0">
          <EmptyState v-if="items.length === 0"/>
          <v-list v-else three-line >
              <template v-for="(item,index) in items">
              <v-list-item :key="index" @click="$router.push({ name: 'chatthread', params: { threadId: item.id, topic: item.topic } })">
                 <v-list-item-avatar>
           <v-icon>mdi-email</v-icon>
          </v-list-item-avatar>
                  <v-list-item-content>
                      <v-list-item-title class="text-h5">{{item.topic}} </v-list-item-title>
                      <v-list-item-subtitle>เมื่อ {{$dayjs(item.lastMessageReceivedOn).local($i18n.locale).format('DD MMMM YYYY HH:mm')}}</v-list-item-subtitle>
                 </v-list-item-content>
               <v-list-item-action>
                    <v-list-item-action-text>{{ $dayjs(item.lastMessageReceivedOn).fromNow() }}</v-list-item-action-text>
               </v-list-item-action>
              </v-list-item>
              <v-divider :key="'d'+index+1"/>
              </template>
          </v-list>
      </v-container>
       <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
      </v-main
    >
</v-app>
</template>

<script>
import SingleAppBar from "@/components/common/SingleAppBar";
// import {postTokenCommService} from "@/api/"
import EmptyState from "@/components/chat/EmptyChatState";
import { mapFields } from "vuex-map-fields";
import { initChat } from '@/plugins/communication'
//import ListCard from "@/components/inbox/ListCard";

export default {
  name: "ChatList",
  components: {
    SingleAppBar,
    EmptyState
    //ListCard
  },
  data() {
    return {
      state: false,
      items: [],
      loading: false
    }
  },
  computed: {
    ...mapFields(['chatToken','chatUser'])
  },
  methods: {
    async fetchData(){
      try {
        this.loading = true
        await initChat();
        if (this.$chatClient) {
        const threads = this.$chatClient.listChatThreads();
        for await (const thread of threads) {
          this.items.push(thread)
        }
        // } else {
        // const chat = await postTokenCommService()
        // if (chat.data.code === 1) {
        //     let service = chat.data.result.filter(el=> el.service === 'personalScreeningService')
        //     if(service.length > 0) {
        //       this.chatToken = service[0].communicationService.token
        //       this.chatUser = service[0].communicationService.user
        //       window.chatClient = new this.$ChatClient('https://comservicevwell.communication.azure.com', new this.$AzureCommunicationTokenCredential(this.chatToken));   
        //       const threads = window.chatClient.listChatThreads();
        // for await (const thread of threads) {
        //   this.items.push(thread)
        // }       
        //     }
        // }
         }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
};
</script>

<style lang="scss" scoped>

</style>