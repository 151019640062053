<template>
   <v-card color="transparent" elevation="0">
          <v-card-text class="text-center">
            <div class="pt-10">
            <v-avatar size="100" >
              <v-img :src="require('@/assets/Grayemptystate.svg')"></v-img>
            </v-avatar>
            </div>
            <div class="pt-5 text-h5">ยังไม่มีข้อความ</div>
          </v-card-text>
        </v-card>
</template>

<script>
export default {

}
</script>

<style>

</style>